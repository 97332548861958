import { useSelector } from '@innedit/formidable/dist';
import { CommandeModel, InneditContext } from '@innedit/innedit-react';
import { CommandeType, DocumentType } from '@innedit/innedit-type';
import { PageProps } from 'gatsby';
import querystring from 'querystring';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CommandeRecapitulatif from '../../components/Commande/Recapitulatif';
import Layout from '../../components/Layout';
import { StateProps } from '../../reducers';

const CommandePage: FC<PageProps> = function ({
  location: { pathname, search },
  params: { id },
}) {
  const params = search ? querystring.parse(search.substring(1)) : {};
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>();
  const [commande, setCommande] = useState<DocumentType<CommandeType>>();

  const { clearCart } = useContext(InneditContext);

  const { user } = useSelector((state: StateProps) => state);
  const authUser = user ? user.authUser : undefined;

  useEffect(() => {
    let isMounted = true;

    if (authUser?.uid) {
      CommandeModel.getByUserId(authUser.uid, id)
        .then(documentSnapshot => {
          if (isMounted) {
            setLoading(false);
            setCommande(documentSnapshot);
          }

          return isMounted;
        })
        .catch(({ message }) => {
          if (isMounted) {
            setLoading(false);
            // setError(message);
          }
        });
    }

    return () => {
      isMounted = false;
    };
  }, [authUser?.uid, id]);

  useEffect(() => {
    if (
      // typeof window !== 'undefined' &&
      commande?.paiementId &&
      commande.paiementId === params.payment_intent
    ) {
      clearCart();
      // let url = `${process.env.GATSBY_INNEDIT_PAYMENTS_URL}`;
      // url += `/espaces/${process.env.GATSBY_ID_BOUTIQUE}/payment_intents/retrieve`;
      //
      // window
      //   .fetch(url, {
      //     body: JSON.stringify({ id: params.payment_intent }),
      //     headers: { 'Content-Type': 'application/json' },
      //     method: 'POST',
      //   })
      //   .then(res => res.json())
      //   .then(data => console.info('data', data))
      //   .catch(({ message }) => setError(message));
    }
  }, [commande?.paiementId, params.payment_intent]);

  if (loading) {
    return (
      <Layout pathname={pathname} showTitle title={t('pages.commande.title')}>
        {t('loading')}
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout pathname={pathname} showTitle title={t('pages.commande.title')}>
        {error}
      </Layout>
    );
  }

  if (!commande) {
    return (
      <Layout pathname={pathname} showTitle title={t('pages.commande.title')}>
        {t('pages.commande.erreur-chargement')}
      </Layout>
    );
  }

  return (
    <Layout pathname={pathname} showTitle title={t('pages.commande.title')}>
      <CommandeRecapitulatif commande={commande} />
    </Layout>
  );
};

export default CommandePage;
